import React from 'react';
import './VideoList.scss';
import VideoItem from './VideoItem';

const VideoList = ({ filteredVideos }) => {
    return (
        <div className="video-grid">
            {filteredVideos.map(video => (
                <VideoItem key={video.id} video={video} />
            ))}
        </div>
    );
}

export default VideoList;
