import React from 'react';
import Navbar from './Navbar';
import './ContactUs.scss';
import { ReactComponent as PhoneIcon } from '../assets/phone.svg'; // You can use SVGs directly in React like this.
import { ReactComponent as EmailIcon } from '../assets/email.svg';
import { ReactComponent as AddressIcon } from '../assets/address.svg';
import Footer from './Footer';

const ContactUs = () => {
    return (
        <div>
            <Navbar />
            <div className="contact-us">
                <div className="contact-form-section">
                    <h2>Get in Touch</h2>
                    <div className="input-wrapper">
                        <input type="text" id="name" required />
                        <label for="name">Your Name</label>
                    </div>
                    <div className="input-wrapper">
                        <input type="email" id="email" required />
                        <label for="email">Your Email</label>
                    </div>
                    <div className="input-wrapper">
                        <textarea id="message" required></textarea>
                        <label for="message">Your Message</label>
                    </div>
                    <button type="submit">Send Message</button>
                </div>
                <div className="contact-info-section">
                    <h2>Contact Info</h2>
                    <div className="info-item">
                        <PhoneIcon />
                        <p>+251 954 455 554</p>
                    </div>
                    <div className="info-item">
                        <EmailIcon />
                        <p>contact@habeshacare.com</p>
                    </div>
                    <div className="info-item">
                        <AddressIcon />
                        <p>123 Health St., Addis Ababa, Ethiopia</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
export default ContactUs;
