import React from 'react';
import HeroSection from './HeroSection';
import FeaturedArticles from './FeaturedArticles';
import FeaturedVideos from './FeaturedVideos';
import BMICalculator from './BMICalculator';
import HealthFactOfTheDay from './HealthFactOfTheDay';
import Slider from './Slider';
import { Link } from 'react-router-dom';




const slides = [
    {
        image: '/assets/habesha-care-doctor.jpg',
        text: 'Join our Health Community today!',
        buttonText: 'Join Now',
        action: () => {
            <Link to="/about"></Link>
        }
    },
    {
        image: '/assets/slider-image-1.jpg',
        text: 'Stay Fit, Stay Happy!',
        buttonText: 'Learn More',
        action: () => {
            <Link to="/blog"></Link>
        }
    },
    {
        image: '/assets/slider-image-2.jpg',
        text: 'Discover Top Medical Facilities!',
        buttonText: 'Learn More',
        action: () => {
            <Link to="/hospital"></Link>
        }
    },
    {
        image: '/assets/slider-image-3.jpg',
        text: 'Your Well-being, Our Priority!',
        buttonText: 'Learn More',
        action: () => {
            <Link to="/doctor"></Link>
        }
    },
];

const HomePage = () => {
    
    return (
        <div>
            <Slider slides={slides} />
            <FeaturedArticles />
            <FeaturedVideos />
            <BMICalculator />
            <HealthFactOfTheDay />
            <HeroSection />
        </div>
    );
}

export default HomePage;


