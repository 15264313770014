import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/habesha-care-bg.png';
import Navbar from './Navbar';
import Footer from './Footer';
import './Auth.scss';

const Login = () => {
    return (
        <>
        <Navbar />
        <div className="auth-container">
            <img src={logo} alt="App Logo" className="auth-logo" />
            <h2>Login</h2>
            <form>
                <input type="email" placeholder="Email" />
                <input type="password" placeholder="Password" />
                <button type="submit">Login</button>
            </form>
            <p>Don't have an account? <Link to="/signup">Signup</Link></p>
        </div>
        <Footer />
        </>
    );
};

export default Login;
