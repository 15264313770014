import React from 'react';
import { Link } from 'react-router-dom';
import './VideoItem.scss';

const VideoItem = ({ video }) => {
    return (
        <Link to={`/vlog/${video.id}`} className="video-item">
            <div className="thumbnail">
                <img src={`https://img.youtube.com/vi/${video.youtubeLink.split('v=')[1]}/0.jpg`} alt={video.title} />
                <div className="play-icon"></div>
            </div>
            <h3>{video.title}</h3>
            <p>{video.description.substring(0, 100)}...</p>
        </Link>
    );
}

export default VideoItem;