// import React, { useState } from 'react';
// import './SearchBar.scss';

// const SearchBar = ({ onSearch }) => {
//     const [searchTerm, setSearchTerm] = useState('');

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         onSearch(searchTerm);
//     }

//     return (
//         <form onSubmit={handleSubmit} className="search-bar">
//             <input 
//                 type="text"
//                 placeholder="Search blogs..."
//                 value={searchTerm}
//                 onChange={e => setSearchTerm(e.target.value)}
//             />
//             <button type="submit">Search</button>
//         </form>
//     );
// }

// export default SearchBar;


import React, { useState } from 'react';
import './SearchBar.scss';

const SearchBar = ({ onSearch }) => {
    const [term, setTerm] = useState('');

    const handleInputChange = (e) => {
        setTerm(e.target.value);
        onSearch(e.target.value);
    };

    return (
        <div className="search-bar">
            <input
                type="text"
                placeholder="Search ..."
                value={term}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default SearchBar;
