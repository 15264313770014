import React, { useState } from 'react';
import Navbar from '../Navbar';
import HospitalList from './HospitalList';
import HospitalSidebar from './HospitalSidebar';
import hospitalsData from '../../data/hospitals.json';
import blogsData from '../../data/blogs.json';
import Footer from '../Footer';
import './HospitalPage.scss';
import HospitalHeader from './HospitalHeader';

const HospitalPage = () => {
    const hospitals = hospitalsData.featured;
    const blogs = blogsData.featured;
    const [filteredHospitals, setFilteredHospitals] = useState(hospitals);

    const handleSearch = (searchTerm, filteredByCategory = null) => {
        let filtered = filteredByCategory || hospitals;
        if (searchTerm) {
            filtered = filtered.filter(hospital => 
                hospital.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredHospitals(filtered);
    };


    return (
        <div>
            <Navbar />
            <HospitalHeader />
            <div className="hospital-page-wrapper">
                <HospitalList hospitals={filteredHospitals} />
                <HospitalSidebar hospitals={blogs} onSearch={handleSearch} />
            </div>
            <Footer />
        </div>
    );
}

export default HospitalPage;
