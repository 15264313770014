import React, { useState } from 'react';
import Navbar from '../Navbar';
import DoctorList from './DoctorList';
import DoctorSidebar from './DoctorSidebar';
import doctorsData from '../../data/doctors.json';
import blogsData from '../../data/blogs.json';
import Footer from '../Footer';
import './DoctorPage.scss';
import DoctorHeader from './DoctorHeader';

const DoctorPage = () => {
    const doctors = doctorsData.featured;
    const blogs = blogsData.featured;
    const [filteredDoctors, setFilteredDoctors] = useState(doctors);

    const handleSearch = (searchTerm, filteredByCategory = null) => {
        let filtered = filteredByCategory || doctors;
        if (searchTerm) {
            filtered = filtered.filter(doctor => 
                doctor.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredDoctors(filtered);
    };


    return (
        <div>
            <Navbar />
            <DoctorHeader />
            <div className="doctor-page-wrapper">
                <DoctorList doctors={filteredDoctors} />
                <DoctorSidebar doctors={blogs} onSearch={handleSearch} />
            </div>
            <Footer />
        </div>
    );
}

export default DoctorPage;
