import React, { useState } from 'react';
import VlogSidebar from './VlogSidebar';
import './VlogPage.scss';
import Navbar from '../Navbar';
import videosData from '../../data/videos.json';
import VideoItem from './VideoItem';
import VideoList from './VideoList';
import VlogHeader from './VlogHeader';
import Footer from '../Footer';

const VlogPage = () => {
    const videos = videosData.featured;
    const [filteredVideos, setFilteredVideos] = useState(videos);

    const handleSearch = (searchTerm, filteredByCategory = null) => {
        let filtered = filteredByCategory || videos;
        if (searchTerm) {
            filtered = filtered.filter(video => 
                video.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredVideos(filtered);
    };

    return (
        <div>
            <Navbar />
            <VlogHeader />
            <div className="video-page-wrapper">
                <VideoList filteredVideos={filteredVideos} />
                <VlogSidebar videos={videos} onSearch={handleSearch} />
            </div>
            <Footer />
        </div>
    );
}

export default VlogPage;