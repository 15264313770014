import React from 'react';
import { useParams } from 'react-router-dom';
import BlogSidebar from './BlogSidebar';
import './BlogDetail.scss';
import Navbar from '../Navbar';
import Footer from '../Footer';
import blogsData from '../../data/blogs.json';

const BlogDetail = () => {
    const blogs = blogsData.blogsData;
    const { id } = useParams();
    const blog = blogs.find(b => b.id === parseInt(id));

    if (!blog) {
        return <div>Blog not found</div>;
    }

    return (
        <div>
            <Navbar />
            <div className="blog-detail-wrapper">
                <article className="blog-detail">
                    <h2>{blog.title}</h2>
                    <img src={blog.image} alt={blog.title} />
                    <p>{blog.content}</p>
                </article>
                <BlogSidebar blogs={blogs} />
            </div>
            <Footer />
        </div>
    );
}

export default BlogDetail;
