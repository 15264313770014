import React from 'react';
import aboutUsImage from '../assets/habesha-care-doctor.jpg'; 
import './AboutUs.scss';
import Navbar from './Navbar';
import Footer from './Footer';

const AboutUs = () => {
    return (
        <>
        <Navbar />
        <div className="aboutus-container">
            <img src={aboutUsImage} alt="About Habesha Care" className="aboutus-image" />
            <div className="aboutus-content">
                <h2>About Habesha Care</h2>
                <p>
                    Founded with a vision to revolutionize the healthcare experience, Habesha Care has emerged as a beacon for those in search of reliable medical information and care. We understand the significance of informed health decisions; hence, we bring you up-to-date articles, insightful blogs, and engaging vlogs focused on medical and wellness topics.
                </p>
                <p>
                    But that's not all. Habesha Care takes pride in offering a meticulously curated directory of hospitals. Here, users can delve into comprehensive insights about each hospital, right from the services they offer to their operating hours and the medical team's expertise.
                </p>
                <p>
                    Recognizing the challenges many face in finding the right healthcare professional, we've integrated a feature allowing users to explore doctor profiles. This ensures that you can not only find the right specialist but also conveniently book an appointment with them.
                </p>
                <p>
                    We are more than just a platform; we are a community. Our commitment is to provide transparent and accessible healthcare information, facilitating better health decisions and promoting overall well-being. Come, be a part of this transformative journey with Habesha Care, and witness a new era of healthcare at your fingertips!
                </p>
            </div>
        </div>
        <Footer />
        </>
    );
};

export default AboutUs;
