import React, { useState } from 'react';
import './BMICalculator.scss';  // Styling

const BMICalculator = () => {
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [bmi, setBmi] = useState(null);

    const calculateBMI = () => {
        const heightM = height / 100;
        const bmiValue = (weight / (heightM * heightM)).toFixed(2);
        setBmi(bmiValue);
    }

    const getBMICategory = (bmi) => {
        if (bmi < 18.5) return "Underweight";
        if (bmi >= 18.5 && bmi < 24.9) return "Normal weight";
        if (bmi >= 25 && bmi < 29.9) return "Overweight";
        if (bmi >= 30) return "Obesity";
    }

    return (
        <div className="bmi-calculator">
            <h2>BMI Calculator</h2>
            
            <div className="bmi-content">
                <div className="bmi-form">
                    <label>
                        Height (cm):
                        <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} />
                    </label>
                    <label>
                        Weight (kg):
                        <input type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
                    </label>
                    <button onClick={calculateBMI}>Calculate BMI</button>
                    
                    {bmi && (
                        <div className="bmi-result">
                            <p>Your BMI is: {bmi}</p>
                            <p>Category: {getBMICategory(bmi)}</p>
                        </div>
                    )}
                </div>
                
                <div className="bmi-info">
                    <p>BMI, or Body Mass Index, is a measure that uses your height and weight to work out if your weight is healthy.</p>
                    <h3>BMI Categories:</h3>
                    <ul>
                        <li><span>Underweight:</span> less than 18.5</li>
                        <li><span>Normal weight:</span> 18.5 – 24.9</li>
                        <li><span>Overweight:</span> 25 – 29.9</li>
                        <li><span>Obesity:</span> 30 or greater</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default BMICalculator;