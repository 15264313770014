import React from 'react';
import { useParams } from 'react-router-dom';
import HospitalSidebar from './HospitalSidebar';
import './HospitalDetail.scss';
import hospitalsData from '../../data/hospitals.json';
import blogsData from '../../data/blogs.json';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

const HospitalDetail = () => {
    const blogs = blogsData.featured;
    const { id } = useParams();
    const hospitals = hospitalsData.featured;
    const hospital = hospitals.find(h => h.id === parseInt(id));

    if (!hospital) {
        return <div>Hospital not found</div>;
    }

    return (
        <div>
            <Navbar />
            <div className="hospital-detail-wrapper">
                <article className="hospital-detail">
                    <h2>{hospital.name}</h2>
                    <img src={hospital.image} alt={hospital.name} />
                    <p>{hospital.description}</p>
                    <div className="services">
                        {hospital.services.map(service => (
                            <div className="service-card" key={service.id} style={{
                                background: `url(${service.image}) no-repeat center center`,
                               backgroundSize: '100% 100%'
                              }}>
                                <h4>{service.name}</h4>
                            </div>
                        ))}
                    </div>
                    <div className="hospital-meta">
                        <div className="hospital-info">
                            <p><strong>Doctors:</strong> {hospital.doctors}</p>
                            <p><strong>Beds:</strong> {hospital.beds}</p>
                            <p><strong>Opening Hours:</strong> {hospital.openingHours}</p>
                            {/* Add other details here */}
                        </div>
                        <div className="hospital-location">
                            <MapContainer center={[hospital.latitude, hospital.longitude]} zoom={13} style={{ width: '100%', height: '300px' }}>
                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                <Marker position={[hospital.latitude, hospital.longitude]}>
                                    <Popup>{hospital.name}</Popup>
                                </Marker>
                            </MapContainer>
                        </div>
                    </div>
                </article>
                <HospitalSidebar hospitals={blogs} />
            </div>
            <Footer />
        </div>
    );
}

export default HospitalDetail;
