
import React from 'react';
import factsData from '../data/healthFacts.json';
import './HealthFactOfTheDay.scss';

const HealthFactOfTheDay = () => {
    const randomFact = factsData.healthFacts[Math.floor(Math.random() * factsData.healthFacts.length)];

    return (
        <div className="health-fact">
            <h3>Health Fact of the Day</h3>
            <p>{randomFact.fact}</p>
        </div>
    );
}

export default HealthFactOfTheDay;
