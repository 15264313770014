// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import './Blog.scss';

// // const Blog = ({ blog }) => {
// //     return (
// //         <Link to={`/blog/${blog.id}`} className="blog-item">
// //             <img src={blog.image} alt={blog.title} />
// //             <h3>{blog.title}</h3>
// //             <p>{blog.excerpt}</p>
// //         </Link>
// //     );
// // }

// // export default Blog;


// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import './Blog.scss';

// // const Blog = ({ blog }) => {
// //     return (
// //         <Link to={`/blog/${blog.id}`} className="blog-item">
// //             <img src={blog.image} alt={blog.title} />
// //             <h3>{blog.title}</h3>
// //             <p>{blog.excerpt}</p>
// //         </Link>
// //     );
// // }
// // export default Blog;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import './Blog.scss';

// const Blog = ({ blog }) => {
//   return (
//     <Link to={`/blog/${blog.id}`} className="blog">
//       <img src={blog.image} alt={blog.title} />
//       <h3>{blog.title}</h3>
//       <p>{blog.intro}</p>
//     </Link>
//   );
// };

// export default Blog;


import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.scss';

const Blog = ({ blog }) => {
    return (
        <Link to={`/blog/${blog.id}`} className="blog-item">
            <img src={blog.image} alt={blog.title} />
            <h3>{blog.title}</h3>
            <p>{blog.content.slice(0, 100)}...</p> 
        </Link>
    );
}

export default Blog;
