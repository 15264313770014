import React from 'react';
import articlesData from '../data/blogs.json';
import './FeaturedArticles.scss';

const FeaturedArticles = () => {
    const slicedArticles = articlesData.blogsData.slice(0,3);
    return (
        <div>
            <h5>Featured Articles</h5>
            <section className="featured">
                {slicedArticles.map(article => (
                    <div key={article.id} className="article-card">
                        <a href={`/blog/${article.id}`}><img src={article.image} alt={article.title} /></a>
                        <h3><a href={`/blog/${article.id}`}>{article.title}</a></h3>
                    
                    </div>
                ))}
            </section>
        </div>
    );
}

export default FeaturedArticles;


// // import React from 'react';
// // import articlesData from '../data/articles.json';
// // import './FeaturedArticles.scss';
// // import Slider from "react-slick";

// // const FeaturedArticles = () => {
// //     const settings = {
// //         dots: true,
// //         infinite: true,
// //         speed: 500,
// //         slidesToShow: 2,
// //         slidesToScroll: 1,
// //         autoplay: true,
// //         autoplaySpeed: 3000,
// //         centerMode: true, // Enable centered view with partial prev/next slides
// //         centerPadding: "90px" // Tweak this value for desired spacing
// //     };
    

// //     return (
// //         <section className="featured">
// //             <Slider {...settings}>
// //                 {articlesData.featured.map(article => (
// //                     <div key={article.id} className="article-card">
// //                         <img src={article.image} alt={article.title} />
// //                         <h3>{article.title}</h3>
// //                         <p>{article.excerpt}</p>
// //                     </div>
// //                 ))}
// //             </Slider>
// //         </section>
// //     );
// // }

// // export default FeaturedArticles;



// import React from 'react';
// import articlesData from '../data/articles.json';
// import Slider from "react-slick";
// import './FeaturedArticles.scss';

// const FeaturedArticles = () => {
//     const settings = {
//         dots: true,  // Show dot indicators
//         infinite: true, // Loop around when end is reached
//         speed: 500,
//         slidesToShow: 1,  // Number of slides to show at once
//         slidesToScroll: 1 // Number of slides to scroll at once
//     };

//     return (
//         <section className="featured">
//             <Slider {...settings}>
//                 {articlesData.featured.map(article => (
//                     <div key={article.id} className="article-card">
//                         <img src={article.image} alt={article.title} />
//                         <h3>{article.title}</h3>
//                         <p>{article.excerpt}</p>
//                     </div>
//                 ))}
//             </Slider>
//         </section>
//     );
// }

// export default FeaturedArticles;
