import React from 'react';
import './VlogHeader.scss';

const VlogHeader = () => {
    return (
        <section className="vlog-header">
            <h2>Our Vlogs</h2>
            <p>Watch our latest health insights.</p>
        </section>
    );
}

export default VlogHeader;
