import React, { useState } from 'react';
import './Slider.scss';

const Slider = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    if (!slides || slides.length === 0) {
        return <div className="slider-placeholder">No slides available</div>;
    }

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <div className="slider">
            <div className="slides-wrapper" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {slides.map((slide, index) => (
                    <div 
                        className="slide" 
                        key={index} 
                        style={{ backgroundImage: `url(${slide.image})` }}
                    >
                        <div className="slide-content">
                            <h2>{slide.text}</h2>
                            <button onClick={slide.action}>{slide.buttonText}</button>
                        </div>
                    </div>
                ))}

            </div>
            <button onClick={prevSlide} className="slider-btn prev">⟨</button>
            <button onClick={nextSlide} className="slider-btn next">⟩</button>
        </div>
    );
}

export default Slider;
