import React from 'react';
import Hospital from './Hospital';
import './HospitalList.scss';

const HospitalList = ({ hospitals }) => {
  return (
    <div className="hospital-grid">
      {hospitals.map((hospital) => (
        <Hospital key={hospital.id} hospital={hospital} />
      ))}
    </div>
  );
};

export default HospitalList;
