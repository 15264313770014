import React from 'react';
import './Doctor.scss';

const Doctor = ({ doctor }) => {
    return (
        <div className="doctor-item">
            <img src={doctor.image} alt={doctor.name} />
            <h3>{doctor.name}</h3>
            <p>{doctor.title}</p> 
            <a href='/login'><button className="book-appointment-button">Book Appointment</button></a>
        </div>
    );
}

export default Doctor;
