import React, { useState } from 'react';
import SearchBar from '../SearchBar';
import './DoctorSidebar.scss';
import categoriesData from '../../data/doctors-categories.json';
import doctorsData from '../../data/doctors.json';
import blogsData from '../../data/blogs.json';

const DoctorSidebar = ({ onSearch }) => {
    const doctors = doctorsData.featured;
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSearchChange = term => {
        setSearchTerm(term);
        onSearch(term);
    };

    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategoryToggle = category => {
        let updatedCategories;
        if (selectedCategories.includes(category)) {
            updatedCategories = selectedCategories.filter(c => c !== category);
        } else {
            updatedCategories = [...selectedCategories, category];
        }
    
        setSelectedCategories(updatedCategories);
        const filtered = doctors.filter(doctor => updatedCategories.includes(doctor.category));
        onSearch(null, updatedCategories.length > 0 ? filtered : null);
    };

    return (
        <aside className="doctor-sidebar">
            <SearchBar onSearch={handleSearchChange} />
            <div className="categories">
                <h4>Categories</h4>
                <ul>
                    {categoriesData.map(category => (
                        <li 
                            key={category} 
                            className={`${category} ${selectedCategories.includes(category) ? 'active' : ''}`} 
                            onClick={() => handleCategoryToggle(category)}
                        >
                            {category}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="recent-posts">
                <h3>Recent Posts</h3>
                <ul>
                    {doctors.slice(0, 2).map(doctor => (
                        <li key={doctor.id}>
                            <a href={`/blog/${doctor.id}`}>{doctor.title}</a>
                            <p>{doctor.excerpt}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
}

export default DoctorSidebar;