import React from 'react';
import './DoctorHeader.scss';

const DoctorHeader = () => {
    return (
        <section className="vlog-header">
            <h2>Doctors</h2>
            <p>Discover and find Doctors near you.</p>
        </section>
    );
}

export default DoctorHeader;
