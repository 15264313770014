

import React from 'react';
import videosData from '../data/videos.json';
import './FeaturedVideos.scss';

const FeaturedVideos = () => {
    const slicedVideos = videosData.featured.slice(0,3);
    return (
        <div>
            <h5>Featured Videos</h5>
            <section className="featured">
                {slicedVideos.map(video => (
                    <div key={video.id} className="video-card">
                        <a href={`/vlog/${video.id}`} className="video-thumb">
                            <img src={`https://img.youtube.com/vi/${video.youtubeLink.split('v=')[1]}/0.jpg`} alt={video.title} />
                            <div className="play-icon"></div>
                        </a>
                        <h3><a href={`/vlog/${video.id}`}>{video.title}</a></h3>
                        <p>{video.excerpt}</p>
                    </div>
                ))}
            </section>
        </div>
    );
}

export default FeaturedVideos;

