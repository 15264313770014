import React from 'react';
import './BlogHeader.scss';

const BlogHeader = () => {
    return (
        <section className="blog-header">
            <h2>Our Blog</h2>
            <p>Stay informed with the latest health insights.</p>
        </section>
    );
}

export default BlogHeader;
