import React, { useState } from 'react';
import SearchBar from '../SearchBar';
import './BlogSidebar.scss';
import categoriesData from '../../data/categories.json';

const BlogSidebar = ({ blogs, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSearchChange = term => {
        setSearchTerm(term);
        onSearch(term);
    };

    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategoryToggle = category => {
        let updatedCategories;
        if (selectedCategories.includes(category)) {
            updatedCategories = selectedCategories.filter(c => c !== category);
        } else {
            updatedCategories = [...selectedCategories, category];
        }
    
        setSelectedCategories(updatedCategories);
        const filtered = blogs.filter(blog => updatedCategories.includes(blog.category));
        onSearch(null, updatedCategories.length > 0 ? filtered : null);
    };

    return (
        <aside className="blog-sidebar">
            <SearchBar onSearch={handleSearchChange} />
            <div className="categories">
                <h4>Categories</h4>
                <ul>
                    {categoriesData.map(category => (
                        <li 
                            key={category} 
                            className={`${category} ${selectedCategories.includes(category) ? 'active' : ''}`} 
                            onClick={() => handleCategoryToggle(category)}
                        >
                            {category}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="recent-posts">
                <h3>Recent Posts</h3>
                <ul>
                    {blogs.slice(0, 2).map(blog => (
                        <li key={blog.id}>
                            <a href={`/blog/${blog.id}`}>{blog.title}</a>
                            <p>{blog.excerpt}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
}

export default BlogSidebar;
