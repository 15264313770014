import React from 'react';
import './HeroSection.scss';

const HeroSection = () => {
    return (
        <section className="hero">
            <h1>Discover our latest health articles</h1>
            <p>Your health matters. Stay informed with HabeshaCare.</p>
            <button>Learn More</button>
        </section>
    );
}

export default HeroSection;

