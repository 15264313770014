import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import VlogSidebar from './VlogSidebar';
import videosData from '../../data/videos.json';
import './VideoDetail.scss';
import Navbar from '../Navbar';
import Footer from '../Footer';

const VideoDetail = () => {
    const { id } = useParams();
    const videos = videosData.featured;
    const video = videos.find(v => v.id === parseInt(id));
    const [selectedCategories, setSelectedCategories] = useState([]);

    if (!video) {
        return <div>Video not found</div>;
    }

    const handleSearch = (e) => {
        console.log(`Searching for: ${e.target.value}`);
        // Implement search logic...
    };

    const handleCategorySelect = (category) => {
        setSelectedCategories(prevState => {
            if (prevState.includes(category)) {
                return prevState.filter(cat => cat !== category);
            } else {
                return [...prevState, category];
            }
        });
        console.log(`Filtering by: ${selectedCategories}`);
        // Implement filtering logic...
    };

    return (
        <div>
            <Navbar />
            <div className="video-detail-container">
                <div className="video-detail-content">
                    <iframe 
                        width="100%" 
                        height="315" 
                        src={`https://www.youtube.com/embed/${video.youtubeLink.split('v=')[1]}`} 
                        title={video.title} 
                        frameBorder="0" 
                        allowFullScreen>
                    </iframe>
                    <h2>{video.title}</h2>
                    <p>{video.description}</p>
                </div>
                <VlogSidebar 
                    videos={videos} 
                    onSearch={handleSearch} 
                    onCategorySelect={handleCategorySelect} 
                    selectedCategories={selectedCategories}
                />
            </div>
            <Footer />
        </div>
    );
}

export default VideoDetail;
