import React from 'react';
import './Hospital.scss';

const Hospital = ({ hospital }) => {
    return (
        <div className="hospital-item">
              <a href={`/hospital/${hospital.id}`}>
                <img src={hospital.image} alt={hospital.name} />
                <h3>{hospital.name}</h3>
            </a>
            <p>{hospital.description}</p> 
        </div>
    );
}

export default Hospital;
