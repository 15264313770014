import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './components/MainContent';
import BlogDetail from './components/Blogs/BlogDetail';
import './App.scss';
import BlogPage from './components/Blogs/BlogPage';
import BlogListPage from './components/Blogs/BlogListPage';
import blogsData from './data/blogs.json';
import VlogPage from './components/Vlogs/VlogPage';
import VideoList from './components/Vlogs/VideoList';
import VideoDetail from './components/Vlogs/VideoDetail';
import ContactUs from './components/ContactUs';
import HospitalPage from './components/Hospital/HospitalPage';
import DoctorPage from './components/Doctor/DoctorPage';
import HospitalDetail from './components/Hospital/HospitalDetail';
import Signup from './components/Signup';
import Login from './components/Login';
import AboutUs from './components/AboutUs';

const blogData = require('./data/blogs.json');
const blogsD = blogData.blogsData;
// Mock data
const blogs = [
  { id: 1, category: "Health", title: "Understanding COVID-19", content: "Content for the blog here...", image: "https://media.istockphoto.com/id/1208604845/vector/healthy-lifestyle-and-self-care-concept.jpg?s=612x612&w=0&k=20&c=4RXl4xGUFpQWHf_LVBRngZRsikqw8BOc51poaItPxMU=" },
  { id: 2, category:"Wellness", title: "How to Stay Healthy", content: "Content for the blog here...", image: "https://media.istockphoto.com/id/480263802/photo/health.jpg?s=612x612&w=is&k=20&c=YZcGHqrxRmkurnIpNJEf0IJIbTi0ckgzBvE0ANUANZw=" },
  { id: 3, category:"Health", title: "How to Stay Healthy", content: "Content for the blog here...", image: "https://thumbs.dreamstime.com/b/good-health-good-life-female-hand-chalk-writing-text-blue-background-97044786.jpg" },
  { id: 4, category:"Health", title: "How to Stay Healthy", content: "Content for the blog here...", image: "https://thumbs.dreamstime.com/b/good-health-best-wealth-card-stethoscope-red-heart-wood-table-medical-concept-72050180.jpg" },
  { id: 5, category:"Meditation", title: "How to Stay Healthy", content: "Content for the blog here...", image: "https://img.freepik.com/free-photo/medical-banner-with-stethoscope_23-2149611199.jpg" },
  { id: 6, category:"Meditation", title: "How to Stay Healthy", content: "Content for the blog here...", image: "https://media.istockphoto.com/id/1280587810/photo/healthy-eating-exercising-weight-and-blood-pressure-control.jpg?b=1&s=612x612&w=0&k=20&c=VVrfTgmWB2kfCkCJbDJQ514mkmQcVQ9cQf44udxOkNA=" },
  
  // ... Add more blog mock data
];

const App = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<MainContent blogs={blogs} />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/video" element={<VlogPage />} />
          <Route path="/blog" element={<BlogPage  />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/vlog/:id" element={<VideoDetail />} />

          <Route path="/hospital" element={<HospitalPage />} />
          <Route path="/hospital/:id" element={<HospitalDetail />} />

          <Route path="/doctor" element={<DoctorPage />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
