import React, { useState } from 'react';
import BlogSidebar from './BlogSidebar';
import './BlogPage.scss';
import Navbar from '../Navbar';
import BlogList from './BlogList';
import BlogHeader from './BlogHeader';
import Footer from '../Footer';
import blogsData from '../../data/blogs.json';


const BlogPage = () => {
    const blogs = blogsData.blogsData;
    const [filteredBlogs, setFilteredBlogs] = useState(blogs);

    const handleSearch = (searchTerm, filteredByCategory = null) => {
        let filtered = filteredByCategory || blogs;
        if (searchTerm) {
            filtered = filtered.filter(blog => 
                blog.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setFilteredBlogs(filtered);
    };

    return (
        <div>
            <Navbar />
            <BlogHeader />
            <div className="blog-page-wrapper">
                <BlogList blogs={filteredBlogs} />
                <BlogSidebar blogs={blogs} onSearch={handleSearch} />
            </div>
            <Footer />
        </div>
    );
}

export default BlogPage;
