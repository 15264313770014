import React from 'react';
import Blog from './Blog';
import './BlogList.scss';

const BlogList = ({ blogs }) => {
  return (
    <div className="blog-grid">
      {blogs.map((blog) => (
        <Blog key={blog.id} blog={blog} />
      ))}
    </div>
  );
};

export default BlogList;
