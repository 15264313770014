import React from 'react';
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="site-footer">
            <div className="footer-main">
                <div className="footer-section about">
                    <h4>About HabeshaCare</h4>
                    <p>Empowering individuals to live healthier lives with the latest health insights and research.</p>
                </div>

                <div className="footer-section contact">
                    <h4>Contact Us</h4>
                    <address>
                        <i className="address-icon"></i> 123 Health St., Addis Ababa, Ethiopia<br/>
                        <i className="phone-icon"></i> +251 954 455 554<br/>
                        <i className="email-icon"></i> contact@habeshacare.com
                    </address>
                </div>

                <div className="footer-section social">
                    <h4>Connect with Us</h4>
                    <div className="social-icons">
                        <a href="#" className="facebook"></a>
                        <a href="#" className="twitter"></a>
                        <a href="#" className="instagram"></a>
                        <a href="#" className="youtube"></a>
                    </div>
                </div>

                <div className="footer-section newsletter">
                    <h4>Subscribe to Our Newsletter</h4>
                    <input type="email" placeholder="Enter your email" />
                    <button>Subscribe</button>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="legal">
                    <a href="#">Privacy Policy</a> | <a href="#">Terms of Use</a>
                </div>

                <div className="copyright">
                    &copy; {new Date().getFullYear()} HabeshaCare. All rights reserved.
                </div>
            </div>
        </footer>
    );
}

export default Footer;
