import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
import logoImage from '../assets/habesha-care-bg.png';
import addressIcon from '../assets/address.svg';
import phoneIcon from '../assets/phone.svg';
import emailIcon from '../assets/email.svg';

const Navbar = () => {
    return (
        <div style={{ marginBottom: '170px' }}>
            <nav className="navbar">
                <div className="navbar-top">
                    <div className="logo-section">
                        <img src={logoImage} alt="HabeshaCare Logo"/>
                    </div>
                    <div className="contact-info">
                        <span className="info-item">
                            <img src={addressIcon} alt="Address"/>
                            123 Health St., Addis Ababa, Ethiopia
                        </span>
                        <span className="info-item">
                            <img src={phoneIcon} alt="Phone"/>
                            +251 954 455 554
                        </span>
                        <span className="info-item">
                            <img src={emailIcon} alt="Email"/>
                            contact@habeshacare.com
                        </span>
                    </div>
                </div>
                <div className="navbar-bottom">
                    <ul className="nav-list">
                        <li><NavLink exact to="/" activeClassName="active-link">Home</NavLink></li>
                        <li><NavLink to="/blog" activeClassName="active-link">Blogs</NavLink></li>
                        <li><NavLink to="/video" activeClassName="active-link">Vlogs</NavLink></li>
                        <li><NavLink to="/hospital" activeClassName="active-link">Hospitals</NavLink></li>
                        <li><NavLink to="/doctor" activeClassName="active-link">Doctors</NavLink></li>
                        <li><NavLink to="/about" activeClassName="active-link">About Us</NavLink></li>
                        <li><NavLink to="/contact" activeClassName="active-link">Contact Us</NavLink></li>
                    </ul>
                    <div className="auth-links">
                        <NavLink to="/signup" activeClassName="active-link">Sign Up  /  </NavLink>
                        <NavLink to="/login" activeClassName="active-link">Login</NavLink>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
