import React from 'react';
import Doctor from './Doctor';
import './DoctorList.scss';

const DoctorList = ({ doctors }) => {
  return (
    <div className="doctor-grid">
      {doctors.map((doctor) => (
        <Doctor key={doctor.id} doctor={doctor} />
      ))}
    </div>
  );
};

export default DoctorList;