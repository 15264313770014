import React from 'react';
import './HospitalHeader.scss';

const HospitalHeader = () => {
    return (
        <section className="vlog-header">
            <h2>Hospitals</h2>
            <p>Discover and Find Hospitals near you.</p>
        </section>
    );
}

export default HospitalHeader;
