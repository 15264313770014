import React, {useState} from 'react';
import Navbar from './Navbar';
import HomePage from './HomePage';
import BlogList from './Blogs/BlogList';
import BlogSidebar from './Blogs/BlogSidebar';
import './MainContent.scss';
import Footer from './Footer';

const MainContent = ({ blogs }) => {
    const [filteredBlogs, setFilteredBlogs] = useState(blogs);
    
    const handleSearch = (searchTerm) => {
        const results = blogs.filter(blog =>
            blog.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredBlogs(results);
    };

    const recentBlogs = blogs.slice(0, 5);

    return (
        <div>
            <Navbar />
            <main className="content-wrapper">
                <HomePage featuredBlogs={recentBlogs} />
            </main>
            <Footer />
        </div>
    );
}

export default MainContent;
