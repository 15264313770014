// import React, {useState} from 'react';
// import './VlogSidebar.scss';
// import categoriesData from '../data/categories.json';
// import videosData from '../data/videos.json';

// const VlogSidebar = ({ onSearch, onCategorySelect }) => {
//     const videos = videosData.featured;
//     const recentVideos = videos.slice(0, 5);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [selectedCategory, setSelectedCategory] = useState(null);

//     const handleSearchChange = term => {
//         setSearchTerm(term);
//         onSearch(term);
//     };

//     const [selectedCategories, setSelectedCategories] = useState([]);

//     const handleCategoryToggle = category => {
//         let updatedCategories;
//         if (selectedCategories.includes(category)) {
//             updatedCategories = selectedCategories.filter(c => c !== category);
//         } else {
//             updatedCategories = [...selectedCategories, category];
//         }
    
//         setSelectedCategories(updatedCategories);
//         const filtered = videos.filter(video => updatedCategories.includes(video.category));
//         onSearch(null, updatedCategories.length > 0 ? filtered : null);
//     };
    
//     return (
//         <aside className="vlog-sidebar">
//             <div className="search-section">
//                 <input type="text" placeholder="Search videos..." onChange={onSearch} />
//             </div>
//             <div className="categories">
//                 <h4>Categories</h4>
//                 {['Nutrition', 'Exercise', 'Mental Health', 'Diet', 'Wellness'].map(category => (
//                     <button 
//                         key={category} 
//                         onClick={() => onCategorySelect(category)}
//                         className={selectedCategories.includes(category) ? 'selected' : ''}
//                     >
//                         {category}
//                     </button>
//                 ))}
//             </div>
//             <div className="recent-videos">
//                 <h4>Recent Videos</h4>
//                 <ul>
//                     {recentVideos.map(video => (
//                         <li key={video.id}>
//                             <a href={`/vlog/${video.id}`}>{video.title}</a>
//                             <p>{video.description.substring(0, 50)}...</p>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </aside>
//     );
// }

// export default VlogSidebar;





import React, { useState } from 'react';
import SearchBar from '../SearchBar';
import './VlogSidebar.scss';
import categoriesData from '../../data/categories.json';
import videosData from '../../data/videos.json';

const VlogSidebar = ({ onSearch }) => {
    const videos = videosData.featured;
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleSearchChange = term => {
        setSearchTerm(term);
        onSearch(term);
    };

    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCategoryToggle = category => {
        let updatedCategories;
        if (selectedCategories.includes(category)) {
            updatedCategories = selectedCategories.filter(c => c !== category);
        } else {
            updatedCategories = [...selectedCategories, category];
        }
    
        setSelectedCategories(updatedCategories);
        const filtered = videos.filter(video => updatedCategories.includes(video.category));
        onSearch(null, updatedCategories.length > 0 ? filtered : null);
    };

    return (
        <aside className="video-sidebar">
            <SearchBar onSearch={handleSearchChange} />
            <div className="categories">
                <h4>Categories</h4>
                <ul>
                    {categoriesData.map(category => (
                        <li 
                            key={category} 
                            className={`${category} ${selectedCategories.includes(category) ? 'active' : ''}`} 
                            onClick={() => handleCategoryToggle(category)}
                        >
                            {category}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="recent-posts">
                <h3>Recent Posts</h3>
                <ul>
                    {videos.slice(0, 2).map(video => (
                        <li key={video.id}>
                            <a href={`/vlog/${video.id}`}>{video.title}</a>
                            <p>{video.excerpt}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </aside>
    );
}

export default VlogSidebar;